import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { UilAngleLeft, UilPlus, UilMinus } from '@iconscout/react-unicons'

import { SET_CART } from 'store/actions'
import { useTelegram } from "./useTelegram"

import { formatMoney } from './helpers';

function Products() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { login, posId } = useParams()
	const { tg } = useTelegram()

	const reduxCart = useSelector(state => state.cart)
	const reduxInformation = useSelector(state => state.information)

	const [cart, setCart] = useState([]);

	function addProduct(product) {
		var cartCopy = [...cart]
		var index = cartCopy.findIndex(item => item.id === product.id)

		if (index === -1 && index !== undefined) { // if not exist same product in the itemsList
			product.quantity = 1
			cartCopy.push(product)
		} else {
			cartCopy[index]['quantity'] += 1
		}

		if (cartCopy.length === 0) {
			tg.MainButton.hide();
		} else {
			tg.MainButton.show();
			tg.MainButton.setParams({
				text: `${t('cart')}: ${getTotalPrice(cartCopy)}`,
				color: reduxInformation?.theme?.button_bg_color_plus,
				textColor: reduxInformation?.theme?.button_text_color,
			})
		}

		setCart(cartCopy)
		dispatch(SET_CART(cartCopy))
	}

	function handleChangeQuantity(product, type) {
		var cartCopy = [...cart]
		var index = cartCopy.findIndex(item => item.id === product.id)

		if (type === 'plus') {
			cartCopy[index]['quantity'] += 1

			tg.MainButton.show();
			tg.MainButton.setParams({
				text: `${t('cart')}: ${getTotalPrice(cartCopy)}`,
				color: reduxInformation?.theme?.button_bg_color_plus,
				textColor: reduxInformation?.theme?.button_text_color,
			})
		}
		if (type === 'minus') {
			if (cartCopy[index]['quantity'] === 1) {
				cartCopy.splice(index, 1)
			} else {
				cartCopy[index]['quantity'] -= 1
			}

			if (cartCopy.length) {
				tg.MainButton.show();
				tg.MainButton.setParams({
					text: `${t('cart')}: ${getTotalPrice(cartCopy)}`,
					color: reduxInformation?.theme?.button_bg_color_plus,
					textColor: reduxInformation?.theme?.button_text_color,
				})
			} else {
				tg.MainButton.hide();
			}
		}
		setCart(cartCopy)
		dispatch(SET_CART(cartCopy))
	}

	function getProductQuantity(id) {
		return cart.find(item => item.id === id)['quantity']
	}

	function boolProductInCart(id) {
		return !!cart.find(item => item.id === id)
	}

	function getTotalPrice(items = []) {
		var totalPrice = 0
		for (let i = 0; i < items.length; i++) {
			totalPrice += items[i]['quantity'] * items[i]['sale_price']
		}
		return formatMoney(totalPrice)
	}

	function goTo(page) {
		if (page === '/cart') {
			dispatch(SET_CART(cart))
			navigate(`/cart/${login}/${posId}`)
			return
		}
	}

	function handleTgButtonClick() {
		goTo('/cart')
	}

	useEffect(() => {
		tg.onEvent('mainButtonClicked', handleTgButtonClick)
		return () => {
			tg.offEvent('mainButtonClicked', handleTgButtonClick)
		}
	}, [handleTgButtonClick]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		window.scrollTo(0, 0)
		if (reduxCart.length) {
			setCart(reduxCart)

			tg.MainButton.show();
			tg.MainButton.setParams({
				text: `${t('cart')}: ${getTotalPrice(reduxCart)}`,
				color: reduxInformation?.theme?.button_bg_color_plus,
				textColor: reduxInformation?.theme?.button_text_color,
			})
		}
		if (!reduxCart.length) {
			tg.MainButton.hide();
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="tg-body">
			<div className="tg-body-inner">
				<div className="tg-header">
					<div className="tg-header-back" onClick={() => navigate(-1)}>
						<UilAngleLeft></UilAngleLeft>
						{t('back')}
					</div>
					<div className="tg-header-title">{reduxInformation.menuName}</div>
					<div className="tg-header-end"></div>
				</div>

				<div className="tg-products-wrapper">
					{reduxInformation?.products?.map((item, index) => (
						<div className="tg-products-item" key={index}>
							{boolProductInCart(item.id) &&
								<span className="tg-products-item-quantity"
									style={{
										'backgroundColor': reduxInformation?.theme?.button_bg_color_plus,
										'color': reduxInformation?.theme?.button_text_color
									}}>
									{getProductQuantity(item.id)}
								</span>
							}
							<div className="tg-product-item-wrapper">
								{item.image ?
									<img src={`https://backend.mison.uz/uploads/products/${item.image}`}
										alt={item.image}
										className="tg-product-item-img" />
									:
									<img src="https://backend.mison.uz/no_image.png" alt="no_image"
										className="tg-product-item-img" />
								}
								<div className="tg-products-item-name"
									style={{
										'color': reduxInformation?.theme?.text_color
									}}>
									{item.name}
								</div>
								<div className="tg-products-item-price"
									style={{
										'color': reduxInformation?.theme?.text_color
									}}>
									{formatMoney(item.sale_price)} {t('sum')}
								</div>
							</div>
							{!boolProductInCart(item.id) ?
								<button className="tg-products-button-add"
									style={{
										'backgroundColor': reduxInformation?.theme?.button_bg_color_plus,
										'color': reduxInformation?.theme?.button_text_color
									}}
									onClick={() => addProduct(item)}>
									{t('add')}
								</button>
								:
								<>
									<div className="d-flex" style={{ 'gap': '10px' }}>
										<button
											className="tg-products-button-add2 ripple"
											style={{
												'backgroundColor': reduxInformation.theme.button_bg_color_minus,
												'color': reduxInformation.theme.button_minus_text_color
											}}
											onClick={() => handleChangeQuantity(item, 'minus')}>
											<UilMinus></UilMinus>
										</button>
										<button
											className="tg-products-button-add2 ripple"
											style={{
												'backgroundColor': reduxInformation?.theme?.button_bg_color_plus,
												'color': reduxInformation?.theme?.button_text_color
											}}
											onClick={() => handleChangeQuantity(item, 'plus')}>
											<UilPlus></UilPlus>
										</button>
									</div>
								</>
							}
						</div>
					))}
				</div>
			</div>

			{(process.env.NODE_ENV === 'development' && reduxCart.length > 0) &&
				<button className="tg-main-button"
					style={{
						'backgroundColor': reduxInformation?.theme?.button_bg_color_plus,
						'color': reduxInformation?.theme?.button_text_color
					}}
					onClick={() => goTo('/cart')}>
					{t('cart')}: {getTotalPrice(reduxCart)}
				</button>
			}
		</div>
	)
}

export default Products