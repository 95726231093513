import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import i18n from "i18n"
import { SET_INFORMATION, SET_INFORMATION_THEME, SET_INFORMATION_INITIAL } from 'store/actions'
import { SET_CART } from 'store/actions'
import { useTelegram } from "./useTelegram"

var URL = 'http://127.0.0.1:8000/api'
if (process.env.NODE_ENV === 'production') {
	URL = 'https://backend.mison.uz/api'
}

function Check() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { login, posId } = useParams()
	const { tg, user } = useTelegram()

	const reduxInformation = useSelector(state => state.information)

	async function checkAuth() {
		const response = await axios.get(`${URL}/menu/${posId}`)

		var selectedTheme = {}
		if (tg?.colorScheme === 'light') {
			selectedTheme = response.data.telegram_theme.light
		}
		if (tg?.colorScheme === 'dark') {
			selectedTheme = response.data.telegram_theme.dark
		}
		if (process.env.NODE_ENV === 'development') {
			selectedTheme = response.data.telegram_theme.light
		}

		try {
			const responseAuth = await axios.post(`${URL}/telegram-web-app/check-auth`, {
				"login": login,
				"telegram_user_id": process.env.NODE_ENV === 'production' ? user.id : "435945143",
			})

			dispatch(SET_INFORMATION({
				'client': responseAuth.data,
				'menuName': "",
				'menu': response.data.menu,
				'products': [],
				'theme': selectedTheme,
			}))

			i18n.changeLanguage(response.data.language)
			navigate(`/menu/${login}/${posId}`)
		} catch (error) {
			dispatch(SET_INFORMATION_THEME(selectedTheme))
			navigate(`/login/${login}/${posId}`)
		}
	}

	useEffect(() => {
		dispatch(SET_CART([]))

		if (reduxInformation?.menu[0]?.login !== login) {
			dispatch(SET_INFORMATION_INITIAL())
		}
		checkAuth()

		return () => { };
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{console.log(tg)}
			<div className="tg-body">
				<div className="tg-body-inner">
					<div className="tg-menu-wrapper">
						{[1, 2, 3, 4, 5, 6]?.map((item, index) => (
							<div className="tg-menu-item" key={index}>
								<Skeleton height={111} width={155} />
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	)
}

export default Check