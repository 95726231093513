import { getTime, format } from 'date-fns'

export function regexLogin(str) {
	if (str) {
		return str.toLowerCase().replace(/[^a-z0-9_]/gi, '');
	}
	return ""
}

export function deleteSymbols(str) {
	if (str) {
		return str.replace(/[^A-Z0-9]/gi, '');
	}
	return ""
}

export function regexNumeric(str) {
	if (str) {
		return str.replace(/[^0-9]/gi, '');
	}
	return ""
}

export function returnSign(str) {
	return str.includes("?") ? '&' : '?'
}

export function regexPhoneNumber(str) {
	if (str) {
		return str.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, "$1$2 $3-$4-$5");
	}
	return ""
}

export function clearTemporaryStorage() {
	localStorage.removeItem('login')
}

export function findKeyFromArrayByValue(array, valueName, value, key) {
	if ((array.length > 0 && value) || value === 0) {
		return array.filter(x => x[valueName] === value)[0][key];
	}
}

export function formatMoney(amount, decimalCount = null, decimal = ".", thousands = " ") {
	if (Number.isInteger(Number(amount))) {
		decimalCount = 0
	} else {
		decimalCount = 2
	}

	try {
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 0 : decimalCount; // 0 was 2

		const negativeSign = amount < 0 ? "-" : "";

		let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
		let j = (i.length > 3) ? i.length % 3 : 0;

		return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
	} catch (e) {
		console.log(e)
	}
}

export function formatDateWithTime(date) {
	if (date) {
		var formattedDate = format(new Date(date), 'dd.MM.yyyy HH:mm');
		return formattedDate
	}
}

export function formatDateCalendar(date) {
	if (date) {
		var formattedDate = format(new Date(date), 'dd MMMM yyyy');
		return formattedDate
	}
}

export function formatDate(date) {
	if (date) {
		var formattedDate = format(new Date(date), 'dd.MM.yyyy');
		return formattedDate
	}
}

export function formatDateBackend(date) {
	if (date) {
		var formattedDate = format(new Date(date), 'yyyy-MM-dd');
		return formattedDate
	}
}

export function getUnixTime() {
	return getTime(new Date())
}

export function todayDate() {
	return format(new Date(), 'dd.MM.yyyy HH:mm:ss')
}

export function todayDDMMYYYY() {
	return format(new Date(), 'dd.MM.yyyy')
}

export function getHHmm() {
	return format(new Date(), 'HH:mm')
}

export function getUnixTimeByDate(date) {
	return getTime(date)
}

export function formatUnixTime(unixTime) {
	if (unixTime)
		return format(new Date(unixTime), 'dd.MM.yyyy HH:mm:ss')
}

export function sumFromArray(array, key) {
	var sum = 0
	if (array && array.length > 0) {
		for (let i = 0; i < array.length; i++) {
			sum += Number(array[i][key]);
		}
	}

	return sum
}

export function returnMinDate(date, days) {
	var result = new Date(date);
	result.setDate(result.getDate() - days);
	return result;
}

export function countTotalFromArray(array = [], key) {
	var count = 0

	if (array?.length > 0) {
		for (let i = 0; i < array.length; i++) {
			count += Number(array[i][key])
		}
	}
	return count
}