import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { UilAngleLeft } from '@iconscout/react-unicons'
import axios from 'axios'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { useTelegram } from "./useTelegram"
import { formatMoney } from './helpers'

var URL = 'http://127.0.0.1:8000/api'
if (process.env.NODE_ENV === 'production') {
	URL = 'https://backend.mison.uz/api'
}

function Order() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { login } = useParams()
	const { tg, user, queryId } = useTelegram()

	const reduxCart = useSelector(state => state.cart)
	const reduxInformation = useSelector(state => state.information)

	const paymentTypes = [
		{ 'id': 1, 'name': t('cash') },
		{ 'id': 2, 'name': t('terminal') }
	]

	const [loader, setLoader] = useState(false);
	const [poses, setPoses] = useState([]);
	const [addresses, setAddresses] = useState([]);
	const [data, setData] = useState({
		'payment_type_id': 1,
		'delivery_type_id': 1,
		'deviceType': null,
		'promo_code': '',
		'promo_code_activated': false,
		'items': [],
		'discounts': [],
		'delivery_price': 0,
	});

	async function handleSelectDeliveryType(type) {
		setData({
			...data,
			'delivery_type_id': type,
			'address_id': null,
			'address': '',
			'pos_name': '',
			'pos_id': '',
			'max_delivery_distance': '',
			'distance': '',
			'delivery_price': '',
			'discount': '',
			'discount_payment_type': '',
			'pos': {},
			'error': false,
			'message': '',
			'total_amount': data.total_amount,
			'total_amount_service': data.total_amount,
		})

		if (type === 2 && !poses.length) {
			setLoader(true)
			var response = await axios.post(`${URL}/telegram-web-app/closest-poses`, {
				'client_id': reduxInformation.client.id
			})
			setPoses(response.data)
			setLoader(false)
		}
	}

	async function findPromocode() {
		if (data.promo_code_activated) return
		setLoader(true)

		try {
			var response = await axios.post(`${URL}/telegram-web-app/promo-codes/find`, {
				'login': login,
				'client_id': reduxInformation.client.id,
				'promo_code': data.promo_code,
			})

			var dataCopy = { ...data }
			if (response?.data?.discount_promo_code_id) {
				dataCopy.discounts = dataCopy.discounts.filter((item) => item.discount_promo_code_id !== response.data.discount_promo_code_id)

				dataCopy.discounts.push({
					'promo_code': response?.data?.promo_code,
					'discount_promo_code_id': response?.data?.discount_promo_code_id,
					'discount_payment_value': response?.data?.discount_payment_value,
					'discount_payment_type': response?.data?.discount_payment_type,
				})
			}
			dataCopy.promo_code_activated = true
			dataCopy.promo_code_checked = data.promo_code
			getTotalPrice(dataCopy)
		} catch (error) {
			toast.error(error.response.data.message)
		}

		setLoader(false)
	}

	async function handleSelectOption(option) {
		setLoader(true)

		if (option.id === -1) {
			navigator.geolocation.getCurrentPosition(onSuccess, onError);
		} else {
			try {
				var sendData = {
					'login': login,
					'client_id': reduxInformation.client.id,
					'total_amount': data.total_amount,
					'delivery_type_id': data.delivery_type_id,
				}
				if (data.delivery_type_id === 1) {
					sendData.address_id = option.id
				}
				if (data.delivery_type_id === 2) {
					sendData.pos_id = option.id
				}
				var response = await axios.post(`${URL}/telegram-web-app/closest-pos`, sendData)
				var dataCopy = { ...data }
				if (response?.data?.discount_service_id) {
					dataCopy.discounts = dataCopy.discounts.filter((item) => item.discount_service_id !== response.data.discount_service_id)

					dataCopy.discounts.push({
						'discount_service_id': response?.data?.discount_service_id,
						'discount_payment_value': Number(response?.data?.discount_payment_value),
						'discount_payment_type': response?.data?.discount_payment_type,
					})
				}
				dataCopy.message = ''
				dataCopy.error = false
				dataCopy.pos = response.data
				dataCopy.address_id = option.id
				dataCopy.address = option.name
				dataCopy.pos_name = response.data.name
				dataCopy.pos_id = response.data.id
				dataCopy.max_delivery_distance = response.data.max_delivery_distance
				dataCopy.distance = response.data.distance
				dataCopy.delivery_price = Number(response.data.delivery_price) ?? 0
				dataCopy.total_amount = data.total_amount
				dataCopy.total_amount_service = response?.data?.total_amount

				getTotalPrice(dataCopy)
				setLoader(false)
				tgButtonDisable(false)
			} catch (error) {
				setLoader(false)
				setData({
					...data,
					'address_id': option.id,
					'address': option.name,
					'pos': error?.response?.data?.pos,
					'error': true,
					'message': error?.response?.data?.message,
				})
				tgButtonDisable(true)
			}
		}
	}

	async function onSuccess(location) {
		if (!location.coords.latitude) {
			setLoader(false)
			setData({
				...data,
				'error': true,
				'message': t('turn_on_location'),
			})
			return
		}

		var sendData = {
			"login": login,
			"latitude": location.coords.latitude,
			"longitude": location.coords.longitude,
			"telegram_user_id": process.env.NODE_ENV === 'production' ? user.id : "435945143",
			"client_id": reduxInformation.client.id,
		}
		const response = await axios.post(`${URL}/telegram-web-app/geo-location`, sendData)
		var addressessCopy = [...addresses]
		addressessCopy.push(response.data.addresses[0])
		setAddresses(addressessCopy)
		setTimeout(() => {
			handleSelectOption(response.data.addresses[0])
		}, 500);
	}

	function onError(error) {
		setLoader(false)
		setData({
			...data,
			'error': true,
			'message': t('turn_on_location'),
		})
	}

	function getTotalPrice(dataCopy) {
		dataCopy.total_amount = 0
		dataCopy.total_amount_service = 0
		for (let i = 0; i < reduxCart.length; i++) {
			dataCopy.total_amount += reduxCart[i]['quantity'] * reduxCart[i]['sale_price']
		}

		dataCopy.total_amount_service = dataCopy.total_amount
		if (dataCopy.delivery_type_id === 1 && Number(dataCopy?.pos?.delivery_price) > 0) {
			dataCopy.total_amount_service = dataCopy.total_amount + Number(dataCopy?.pos?.delivery_price)
		}

		dataCopy.discount = 0
		for (let i = 0; i < dataCopy.discounts.length; i++) {
			dataCopy.discounts[i]['discount'] = 0
			if (dataCopy.discounts[i]['discount_payment_type'] === 1) { // Сумма
				dataCopy.discounts[i]['discount'] += Number(dataCopy.discounts[i]['discount_payment_value'])
				dataCopy.discount += Number(dataCopy.discounts[i]['discount_payment_value'])
			}
			if (dataCopy.discounts[i]['discount_payment_type'] === 2) { // Процент
				dataCopy.discounts[i]['discount'] = dataCopy.total_amount_service * dataCopy.discounts[i]['discount_payment_value'] / 100
				dataCopy.discount += dataCopy.total_amount_service * dataCopy.discounts[i]['discount_payment_value'] / 100
			}
		}
		dataCopy.total_amount_service -= Number(dataCopy.discount)

		setData(dataCopy)
	}

	function tgButtonDisable(bool = false) {
		if (bool) {
			tg.MainButton.show();
			tg.MainButton.setParams({
				text: t('checkout'),
				color: "#F4F4F4",
				textColor: "#000000",
				is_active: false
			})
		} else {
			tg.MainButton.show();
			tg.MainButton.setParams({
				text: t('checkout'),
				color: reduxInformation?.theme?.button_bg_color_plus,
				textColor: reduxInformation?.theme?.button_text_color,
				is_active: true
			})
		}
	}

	const handleTgButtonClick = useCallback(() => {
		setLoader(true)
		var sendData = {
			'login': login,
			'pos_id': data.pos_id,
			'client_id': reduxInformation.client.id,
			'telegram_user_id': process.env.NODE_ENV === 'production' ? user.id : "435945143",
			'products': reduxCart,
			'query_id': queryId,
			'note': data.note ?? '',
			'delivery_price': data?.delivery_price,
			'distance': data?.distance,
			'delivery_type_id': data?.delivery_type_id,
			'payment_type_id': data?.payment_type_id,
			'discount': data?.discount,
			'discounts': data?.discounts,
			'discount_service_id': data?.discount_service_id,
			'promo_code': data?.promo_code_activated ? data?.promo_code : '',
		}
		if (data.delivery_type_id === 1) {
			sendData.address_id = data.address_id
		}
		if (data.delivery_type_id === 2) {
			sendData.pos_id = data.pos_id
		}

		axios.post(
			`${URL}/telegram-web-app/order`,
			sendData,
			{ headers: { "Accept-Language": reduxInformation?.client?.language } }
		).then(() => {
			tg.close()
		}).catch(error => {
			setLoader(false)
			toast.error(error.response.data.message)
		})
	}, [data]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		tg.onEvent('mainButtonClicked', handleTgButtonClick)
		return () => {
			tg.offEvent('mainButtonClicked', handleTgButtonClick)
		}
	}, [handleTgButtonClick]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		tg.expand()
		tgButtonDisable(true)

		var myLocation = []
		var userAgent = navigator.userAgent.toLowerCase();
		var isAndroid = userAgent.indexOf("android") > -1;
		var isIOS = /iphone|ipad|ipod/.test(userAgent);

		if (isAndroid) {
			myLocation.push({ 'id': -1, 'name': t('my_location') })
			setData({ ...data, 'deviceType': 'android' })
		}
		if (isIOS) setData({ ...data, 'deviceType': 'ios' })

		if (reduxInformation?.client?.addresses) {
			myLocation = myLocation.concat(reduxInformation?.client?.addresses)
			setAddresses(myLocation)
		}

		return () => {
			tg.MainButton.show();
			tg.MainButton.setParams({
				text: t('checkout'),
				color: "#F4F4F4",
				textColor: "#000000",
				is_active: true
			})
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getTotalPrice(data)
	}, [data.delivery_type_id]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="tg-body">
			{console.log(data)}
			<div className="tg-body-inner">
				<div className="tg-header">
					<div className="tg-header-back" onClick={() => navigate(-1)}>
						<UilAngleLeft></UilAngleLeft>
						{t('back')}
					</div>
					<div className="tg-header-title">{t('information')}</div>
					<div className="tg-header-end"></div>
				</div>

				<div className="form-group mb-2">
					<input type="text" className="form-control" name="note" autoComplete="off" autoFocus
						style={{ 'border': `1px solid ${reduxInformation?.theme?.button_bg_color_plus}` }}
						placeholder={t('note')}
						onChange={(e) => setData({ ...data, 'note': e.target.value })} />
				</div>

				<div className="form-group mb-3">
					<label>{t('payment_type')}</label>
					<Select
						options={paymentTypes}
						value={paymentTypes.find(option => option.id === data.payment_type_id) || ''}
						onChange={(option) => setData({ ...data, 'payment_type_id': option.id })}
						isSearchable={false}
						placeholder=""
						noOptionsMessage={() => ''}
						getOptionLabel={(option) => option.name}
						getOptionValue={(option) => option.id}
						styles={{
							option: (defaultStyles, state) => ({
								...defaultStyles,
								color: state.isSelected ? "#fff" : "#222222",
								backgroundColor: state.isSelected ? reduxInformation?.theme?.button_bg_color_plus : "#fff"
							}),
							control: (defaultStyles, state) => ({
								...defaultStyles,
								borderColor: reduxInformation?.theme?.button_bg_color_plus,
								boxShadow: 'none',
								'&:hover': {
									border: `1px solid ${reduxInformation?.theme?.button_bg_color_plus}`,
								}
							}),
						}}
					/>
				</div>

				<div className="form-group mb-3">
					<div className="d-flex">
						<div className="form-check me-5">
							<div className="radio-round"
								onClick={() => handleSelectDeliveryType(1)}
								style={{ 'backgroundColor': data.delivery_type_id === 1 ? reduxInformation?.theme?.button_bg_color_plus : '' }}>
							</div>
							<span onClick={() => handleSelectDeliveryType(1)}>
								{t('delivery')}
							</span>
						</div>

						<div className="form-check">
							<div className="radio-round"
								onClick={() => handleSelectDeliveryType(2)}
								style={{ 'backgroundColor': data.delivery_type_id === 2 ? reduxInformation?.theme?.button_bg_color_plus : '' }}>
							</div>
							<span onClick={() => handleSelectDeliveryType(2)}>
								{t('pickup')}
							</span>
						</div>
					</div>
				</div>

				{data.delivery_type_id === 1 &&
					<div className="form-group mb-2">
						<Select
							options={addresses}
							value={addresses.find(option => option.id === data.address_id) || ''}
							onChange={(option) => handleSelectOption(option)}
							isSearchable={false}
							placeholder={t('select_address')}
							noOptionsMessage={() => ''}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
							styles={{
								option: (defaultStyles, state) => ({
									...defaultStyles,
									color: state.isSelected ? "#fff" : "#222222",
									backgroundColor: state.isSelected ? reduxInformation?.theme?.button_bg_color_plus : "#fff"
								}),
								control: (defaultStyles, state) => ({
									...defaultStyles,
									borderColor: reduxInformation?.theme?.button_bg_color_plus,
									boxShadow: 'none',
									'&:hover': {
										border: `1px solid ${reduxInformation?.theme?.button_bg_color_plus}`,
									}
								}),
							}}
						/>
					</div>
				}
				{data.delivery_type_id === 2 &&
					<div className="form-group mb-2">
						<Select
							options={poses}
							value={poses.find(option => option.id === data.pos_id) || ''}
							onChange={(option) => handleSelectOption(option)}
							isSearchable={false}
							placeholder={t('select_pos')}
							noOptionsMessage={() => ''}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
							styles={{
								option: (defaultStyles, state) => ({
									...defaultStyles,
									color: state.isSelected ? "#fff" : "#222222",
									backgroundColor: state.isSelected ? reduxInformation?.theme?.button_bg_color_plus : "#fff"
								}),
								control: (defaultStyles, state) => ({
									...defaultStyles,
									borderColor: reduxInformation?.theme?.button_bg_color_plus,
									boxShadow: 'none',
									'&:hover': {
										border: `1px solid ${reduxInformation?.theme?.button_bg_color_plus}`,
									}
								}),
							}}
						/>
					</div>
				}

				<div className="form-group mb-2" style={{ 'position': 'relative' }}>
					<input type="text" className="form-control" name="promo_code" autoComplete="off"
						style={{
							'border': `1px solid ${reduxInformation?.theme?.button_bg_color_plus}`,
							'paddingRight': '130px'
						}}
						placeholder={t('promo_code')}
						value={data.promo_code ?? ''}
						disabled={!(!data?.promo_code_activated)}
						onChange={(e) => setData({ ...data, 'promo_code': e.target.value.toLocaleUpperCase() })} />
					<button className="custom-button"
						style={{
							'color': reduxInformation?.theme?.button_text_color,
							'backgroundColor': reduxInformation?.theme?.button_bg_color_plus,
							'padding': '0 10px',
							'width': '130px',
							'borderRadius': '0 0.25rem 0.25rem 0',
						}}
						disabled={!(data?.promo_code?.length >= 2 && !data?.promo_code_activated)}
						onClick={findPromocode}>
						{t('activate')}
					</button>
				</div>

				{(data.deviceType === 'ios' && addresses.length === 1) &&
					<div className="text-center" style={{ 'color': '#F8A91B' }}>
						{t('check_address')}
					</div>
				}

				{data.error &&
					<div className="text-center" style={{ 'color': '#f46a6a' }}>
						{data.message}
					</div>
				}

				{data.delivery_type_id === 1 &&
					<>
						<div className="d-flex justify-content-between">
							<span className="text-nowrap fw-500 w-40">{t('address')}:</span>
							<span className="text-end w-60">{data.address ?? '-'}</span>
						</div>
						<hr className="hr" />
					</>
				}

				<div className="d-flex justify-content-between">
					<span className="text-nowrap fw-500 w-40">{t('branch')}:</span>
					<span className="text-end w-60">{data?.pos?.name ?? '-'}</span>
				</div>
				<hr className="hr" />
				<div className="d-flex justify-content-between">
					<span className="text-nowrap fw-500 w-40">{t('distance')}:</span>
					<span className="text-end w-60">{data?.pos?.distance ?? '-'} {t('km')}</span>
				</div>
				<hr className="hr" />
				<div className="d-flex justify-content-between">
					<span className="fw-500 w-40">{t('amount')}:</span>
					<span className="text-end w-60">{formatMoney(data.total_amount)}</span>
				</div>
				<hr className="hr" />
				<div className="d-flex justify-content-between">
					<span className="fw-500 w-40">{t('delivery')}:</span>
					{(data?.pos?.delivery_price >= 0 && data?.address_id) &&
						<span className="text-end w-60">{formatMoney(data?.pos?.delivery_price)}</span>
					}
					{(!data?.pos?.delivery_price && !data?.address_id) &&
						<span className="text-end w-60">-</span>
					}
				</div>
				<hr className="hr" />
				{!!data.discount &&
					<>
						<div className="d-flex justify-content-between">
							<span className="fw-500 w-40">{t('discount')}:</span>
							<span className="text-end w-60">{formatMoney(data.discount)}</span>
						</div>
						<hr className="hr" />
					</>
				}
				<div className="d-flex justify-content-between"
					style={{ 'fontSize': '20px', 'paddingBottom': '20px' }}>
					<b className="w-40">{t('to_pay')}:</b>
					<b className="text-end w-60">{formatMoney(data.total_amount_service)}</b>
				</div>
			</div>

			{process.env.NODE_ENV === 'development' &&
				<button className="tg-main-button" disabled={!(!data.error)}
					style={{
						'backgroundColor': !data.error ? reduxInformation?.theme?.button_bg_color_plus : '#F4F4F4',
						'color': !data.error ? reduxInformation?.theme?.button_text_color : '#000000',
					}}
					onClick={() => handleTgButtonClick()}>
					{t('checkout')}
				</button>
			}

			{loader &&
				<div id="loading-bg">
					<div className="loading">
						<div className="effect-1 effects"></div>
						<div className="effect-2 effects"></div>
						<div className="effect-3 effects"></div>
					</div>
				</div>
			}
		</div >
	)
}

export default Order;