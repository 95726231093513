import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { SET_INFORMATION } from 'store/actions'
import { useTelegram } from "./useTelegram"
import { formatMoney } from './helpers'

function Index() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { login, posId } = useParams()
	const { tg } = useTelegram()

	const reduxCart = useSelector(state => state.cart)
	const reduxInformation = useSelector(state => state.information)

	const [menu, setMenu] = useState([]);
	const [client, setClient] = useState({});

	function handleSelectMenu(index) {
		dispatch(SET_INFORMATION({
			'client': reduxInformation?.client?.id ? reduxInformation?.client : client,
			'menuName': menu[index]['name'],
			'menu': menu,
			'products': menu[index]['products'],
			'theme': reduxInformation.theme,
		}))
		navigate(`/products/${login}/${posId}`)
	}

	function getTotalPrice(items = []) {
		var totalAmount = 0
		for (let i = 0; i < items.length; i++) {
			totalAmount += items[i]['quantity'] * items[i]['sale_price']
		}

		return formatMoney(totalAmount)
	}

	function handleTgButtonClick() {
		dispatch(SET_INFORMATION({
			'client': reduxInformation?.client?.id ? reduxInformation?.client : client,
			'menuName': '',
			'menu': menu,
			'products': [],
			'theme': reduxInformation.theme,
		}))
		navigate(`/cart/${login}/${posId}`)
	}

	useEffect(() => {
		tg.onEvent('mainButtonClicked', handleTgButtonClick)
		return () => {
			tg.offEvent('mainButtonClicked', handleTgButtonClick)
		}
	}, [handleTgButtonClick]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setMenu(reduxInformation.menu)
		setClient(reduxInformation.client)

		if (reduxCart.length) {
			tg.MainButton.show();
			tg.MainButton.setParams({
				text: `${t('cart')}: ${getTotalPrice(reduxCart)}`,
				color: reduxInformation?.theme?.button_bg_color_plus,
				textColor: reduxInformation?.theme?.button_text_color,
			})
		}
		if (!reduxCart.length) {
			tg.MainButton.hide();
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="tg-body">
			<div className="tg-body-inner">
				<div className="tg-header-single">
					<div className="tg-header-title">{t('menu')}</div>
				</div>

				<div className="tg-menu-wrapper">
					{menu?.map((item, index) => (
						<div className="tg-menu-item" key={index}
							onClick={() => handleSelectMenu(index)}>
							<div>
								{item.image ?
									<img src={`https://backend.mison.uz/uploads/menu/${item.image}`}
										alt={item.image} />
									:
									<img src='https://backend.mison.uz/no_image.png' alt="no_image" />
								}
							</div>
							<div style={{
								'color': reduxInformation?.theme?.text_color
							}}>
								{item.name}
							</div>
						</div>
					))}
				</div>
			</div>

			{(process.env.NODE_ENV === 'development' && reduxCart.length > 0) &&
				<button className="tg-main-button"
					style={{
						'backgroundColor': reduxInformation?.theme?.button_bg_color_plus,
						'color': reduxInformation?.theme?.button_text_color
					}}
					onClick={() => navigate(`/cart/${login}/${posId}`)}>
					{t('cart')}: {getTotalPrice(reduxCart)}
				</button>
			}
		</div>
	)
}

export default Index