import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from './store'
import { Provider } from 'react-redux'
import { throttle } from 'lodash';
import { saveState } from './store/localStorage'

import App from './App';

store.subscribe(throttle(() => {
	saveState(store.getState())
}, 1000));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

