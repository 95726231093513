import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import axios from 'axios'
import { UilAngleLeft } from '@iconscout/react-unicons'

import { useTelegram } from "./useTelegram"

var URL = 'http://127.0.0.1:8000/api'
if (process.env.NODE_ENV === 'production') {
	URL = 'https://backend.mison.uz/api'
}

function ActivationCode() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { login, posId } = useParams()
	const { tg, user } = useTelegram()

	const reduxInformation = useSelector(state => state.information)
	const reduxRegistration = useSelector(state => state.registration)

	const [otp, setOtp] = useState('');

	async function handleTgButtonClick() {
		var sendData = { ...reduxRegistration }
		sendData.otp = otp
		sendData.login = login
		sendData.telegram_user_id = process.env.NODE_ENV === 'production' ? user.id : "435945143"

		try {
			await axios.post(
				`${URL}/telegram-web-app/registration`,
				sendData,
				{ headers: { "Accept-Language": sendData.language } }
			)
			navigate(`/check/${login}/${posId}`)
		} catch (error) {
			toast.error(error.response.data.message)
		}
	}

	useEffect(() => {
		tg.onEvent('mainButtonClicked', handleTgButtonClick)
		return () => {
			tg.offEvent('mainButtonClicked', handleTgButtonClick)
		}
	}, [handleTgButtonClick]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (otp.length === 6) {
			tg.MainButton.show();
			tg.MainButton.setParams({
				text: t('registration'),
				color: reduxInformation?.theme?.button_bg_color_plus,
				textColor: reduxInformation.theme.button_minus_text_color,
				is_active: true
			})
		} else {
			tg.MainButton.show();
			tg.MainButton.setParams({
				text: t('registration'),
				color: "#F4F4F4",
				textColor: "#000000",
				is_active: false
			})
		}
	}, [otp]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="tg-body">
			<div className="tg-body-inner">

				<div className="tg-header">
					<div className="tg-header-back" onClick={() => navigate(-1)}>
						<UilAngleLeft></UilAngleLeft>
						{t('back')}
					</div>
					<div className="tg-header-title">{t('sms_code')}</div>
					<div className="tg-header-end"></div>
				</div>

				<div className="form-group mb-2">
					<label>{t('sms_code')}</label>
					<input type="number" className="form-control" name="otp" autoComplete="off" autoFocus
						style={{ 'border': `1px solid ${reduxInformation?.theme?.button_bg_color_plus}` }}
						onChange={(e) => setOtp(e.target.value)} />
				</div>
			</div>

			{process.env.NODE_ENV === 'development' &&
				<button className="tg-main-button"
					style={!(otp.length === 6) ?
						{
							backgroundColor: "#F4F4F4",
							color: "#000000",
						} :
						{
							backgroundColor: reduxInformation?.theme?.button_bg_color_plus,
							color: reduxInformation?.theme?.button_text_color,
						}
					}
					disabled={!(otp.length === 6)}
					onClick={handleTgButtonClick}>
					{t('registration')}
				</button>
			}
		</div>
	)
}

export default ActivationCode