import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'
import axios from 'axios'

import i18n from '../i18n'
import { useTelegram } from "./useTelegram"
import { SET_REGISTRATION_INFO } from 'store/actions'

var URL = 'http://127.0.0.1:8000/api'
if (process.env.NODE_ENV === 'production') {
	URL = 'https://backend.mison.uz/api'
}

function Login() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { login, posId } = useParams()
	const { tg, user } = useTelegram()

	const reduxInformation = useSelector(state => state.information)

	const languages = [
		{ 'id': 'ru', 'name': t('russian') },
		{ 'id': 'uzbek_latn', 'name': t('uzbek_latn') },
		{ 'id': 'uzbek_cyrl', 'name': t('uzbek_cyrl') }
	]

	const [data, setData] = useState({
		"first_name": '',
		"phone": '',
		"language": 'uzbek_latn'
	});

	async function handleTgButtonClick() {
		var dataCopy = { ...data }
		dataCopy.phone = '998' + dataCopy.phone
		dataCopy.login = login
		dataCopy.telegram_user_id = process.env.NODE_ENV === 'production' ? user.id : "435945143"

		try {
			await axios.post(
				`${URL}/telegram-web-app/activation-code`,
				dataCopy,
				{ headers: { "Accept-Language": dataCopy.language } }
			)
			dispatch(SET_REGISTRATION_INFO({
				'first_name': dataCopy.first_name,
				'phone': dataCopy.phone,
				'language': dataCopy.language,
			}))
			navigate(`/activation-code/${login}/${posId}`)
		} catch (error) {
			toast.error(error.response.data.message)
		}
	}

	function handleSelect(option) {
		setData({ ...data, 'language': option.id })
		i18n.changeLanguage(option.id)
		localStorage.setItem("I18N_LANGUAGE", option.id)
	}

	useEffect(() => {
		tg.onEvent('mainButtonClicked', handleTgButtonClick)
		return () => {
			tg.offEvent('mainButtonClicked', handleTgButtonClick)
		}
	}, [handleTgButtonClick]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (data.first_name && data.phone.length === 9) {
			tg.MainButton.show();
			tg.MainButton.setParams({
				text: t('get_code'),
				color: reduxInformation?.theme?.button_bg_color_plus,
				textColor: reduxInformation?.theme?.button_minus_text_color,
				is_active: true
			})
		} else {
			tg.MainButton.show();
			tg.MainButton.setParams({
				text: t('get_code'),
				color: "#F4F4F4",
				textColor: "#000000",
				is_active: false
			})
		}
	}, [data]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="tg-body">
			<div className="tg-body-inner">
				<div className="tg-header-single">
					<div className="tg-header-title">{t('registration')}</div>
				</div>

				<div className="form-group mb-2">
					<label>{t('language')}</label>
					<Select
						options={languages}
						value={languages.find(option => option.id === data.language) || ''}
						onChange={(option) => handleSelect(option)}
						isSearchable={false}
						placeholder=""
						noOptionsMessage={() => ''}
						getOptionLabel={(option) => option.name}
						getOptionValue={(option) => option.id}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								borderColor: reduxInformation?.theme?.button_bg_color_plus,
							}),
						}}
					/>
				</div>

				<div className="form-group mb-2">
					<label>{t('name')}</label>
					<input type="text" className="form-control" name="first_name" autoComplete="off" autoFocus
						style={{ 'border': `1px solid ${reduxInformation?.theme?.button_bg_color_plus}` }}
						placeholder={t('example_name')}
						onChange={(e) => setData({ ...data, 'first_name': e.target.value })} />
				</div>

				<div className="form-group mb-3">
					<label>{t('phone')}</label>
					<div className="input-group">
						<span className="input-group-text"
							style={{ 'border': `1px solid ${reduxInformation?.theme?.button_bg_color_plus}` }}>+998</span>
						<input type="text" className="form-control" name="phone" autoComplete="off"
							style={{ 'border': `1px solid ${reduxInformation?.theme?.button_bg_color_plus}` }}
							value={data.phone}
							onChange={(e) => setData({ ...data, 'phone': e.target.value.replace(/[^0-9.]/g, '') })} />
					</div>
				</div>
			</div>

			{process.env.NODE_ENV === 'development' &&
				<button className="tg-main-button"
					style={!(data.first_name && data.phone && data.phone.length === 9) ?
						{
							backgroundColor: "#F4F4F4",
							color: "#000000",
						} :
						{
							backgroundColor: reduxInformation?.theme?.button_bg_color_plus,
							color: reduxInformation?.theme?.button_text_color,
						}
					}
					disabled={!(data.first_name && data.phone && data.phone.length === 9)}
					onClick={handleTgButtonClick}>
					{t('get_code')}
				</button>
			}
		</div>
	)
}

export default Login